const routes = [
  {
    index: true,
    label: "Andrew Weatherman",
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Visualizations',
    path: '/viz',
  },
  {
    label: 'Some Code',
    path: 'https://viz.aweatherman.com',
  },
  {
    label: 'Spotify',
    path: '/spotify',
  },
  {
    label: 'Stats',
    path: '/stats',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
